import React, { useState, useEffect, memo } from 'react'
import _ from 'lodash'
import LoaderThreeDots from '../Common/loader';
import styles from './related.module.scss'
import { Link } from 'gatsby'

import comment from '../img/blog/Icon_comment.svg'
import eye from '../img/blog/Icon_eye.svg'
import linkedin from '../img/icons/linkedin.svg'
import twitter from '../img/icons/twitter.svg'
import facebook from '../img/icons/facebook.svg'


const contentful = require('contentful')
const SPACE_ID = 'ybp5qgtpgrt5'
const ACCESS_TOKEN = 'K_7k8sA5YQcFC0FrjVJjPTxHS0q2JTNLE8amTFWV4EM'

const client = contentful.createClient({
  space: SPACE_ID,
  accessToken: ACCESS_TOKEN
})

const RelatedPosts = ({ category, md, setRelatedPostsVisibility }) => {
  const [relatedPosts, setRelatedPosts] = useState([])
  const [loading, setLoadingValue] = useState(true);

  useEffect(() => {
    getPostsData();
  }, [])

  const getPostsData = async () => {
    const related = [];
    try {
      await client.getEntries()
        .then((response) => {
          response.items.forEach(post => {
            if (post.fields.category === category && post.fields.md !== md) {
              related.push(post);
              setRelatedPosts(related);
              setRelatedPostsVisibility(true);
            }
          })
          setLoadingValue(false);
        })
    } catch (error) {
      console.log('error: getPostsData', error)
    }
  }
  return (
    <>
      <div className={styles.content}>
        {
          loading ? <LoaderThreeDots /> :
            !_.isEmpty(relatedPosts) && _.take(relatedPosts, 3).map((item, key) =>
            (<div key={key + item.fields.md}>
              <div>
                <img className={styles.headerImg} src={item.fields.previewImg.fields.file.url} width="auto" height="auto" alt='img' />
                <img className={styles.icon} src={comment} width="auto" height="auto" alt='comment' /><img className={styles.iconEye} src={eye} width="auto" height="auto" alt='eye' />
              </div>
              <div className={styles.avatar}>
                <img src={item.fields.avatar.fields.file.url} width="auto" height="auto" alt="avatar" /><span>{item.fields.authorName}</span>
              </div>
              <div className={styles.social}>
                <a target='_blank' rel="noopener noreferrer" href={`https://www.linkedin.com/shareArticle?mini=true&url=https://duefocus.com/blog/${item.fields.md}/`}><img src={linkedin} width="auto" height="auto" alt="linkedin" /></a>
                <a target='_blank' rel="noopener noreferrer" href={`https://twitter.com/share?url=https://duefocus.com/blog/${item.fields.md}/`}><img src={twitter} width="auto" height="auto" alt="twitter" /></a>
                <div posts-href={`https://duefocus.com/blog/${item.fields.md}/`}><a rel="noopener noreferrer" target="_blank" href="https://www.facebook.com/sharer/sharer.php?u=https%3A%2F%2Fduefocus.com%2F&amp;src=sdkpreparse"><img src={facebook} width="auto" height="auto" alt="facebook" /></a></div>
              </div>
              <div>
                <Link to={`/blog/${item.fields.md}/`}>
                  <h3>{item.fields.title}</h3>
                  <p>{item.fields.previewText}</p>
                  <span>Read more</span>
                </Link>
              </div>
            </div>)
            )
        }
      </div>
    </>
  )
}

export default memo(RelatedPosts);
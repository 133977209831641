import { documentToHtmlString } from '@contentful/rich-text-html-renderer';
import { BLOCKS } from '@contentful/rich-text-types';
import { graphql } from "gatsby";
import { Helmet } from "react-helmet";
import React from 'react';
import moment from 'moment';
import styles from './index.module.scss';
import Header from '../components/HeaderWhite/header';
import Footer from '../components/Footer/footer';
import RelatedPosts from '../components/Blog/relatedPosts';
import ProgressLine from '../components/Common/srollProgressLine';
// import buttons from '../buttons.module.scss';

import linkedin from '../components/img/icons/linkedin.svg';
import twitter from '../components/img/icons/twitter.svg';
import facebook from '../components/img/icons/facebook.svg';

const Template = ({ data }) => {
  if (!data) { return <></> }
  const options = {
    renderNode: {
      [BLOCKS.EMBEDDED_ASSET]: (node) => {
        return `<img src='${node.data.target.fields.file["en-US"].url}' 
                         width="auto" height="auto" alt='${node.data.target.fields.title["en-US"]}' 
                     />`
      }
    }
  }
  // const [ isHaveRelatedPosts, setHaveRelatedPosts ] = React.useState(false);
  const { node } = data.allContentfulPost.edges[0];
  const text = node ? documentToHtmlString(node.description.json, options) : '';

  // const setRelatedPostsVisibility = isVisible => {
  //   setHaveRelatedPosts(isVisible);
  // }

  // React.useEffect(() => {
  // }, [isHaveRelatedPosts])

  return (
    <>
      <Helmet>
        <html lang="en" />
        <meta charSet="utf-8" />
        <title>{node.previewText}</title>
        <meta name="description" content={node.previewText} />
        <meta property="og:type" content="website" />
        <meta property="og:image" content={node.previewImg.file.url} />
        <meta name="twitter:site" content={`https://duefocus.com/blog/${node.md}`} />
        <meta name="twitter:title" content={node.previewText} />
        <meta name="twitter:description" content={node.PreviewText} />
        <meta name="twitter:image:src" content={node.previewImg.file.url} />
      </Helmet>

      <Header />
      <ProgressLine />
      <div className={styles.main}>
        <div className={styles.socialPanel}>
          <a target='_blank' rel="noopener noreferrer" href={`https://www.linkedin.com/shareArticle?mini=true&url=https://duefocus.com/blog/${node.md}`}><img src={linkedin} width="auto" height="auto" alt="linkedin" /></a>
          <a target='_blank' rel="noopener noreferrer" href={`https://twitter.com/share?url=http://duefocus.com/blog/${node.md}`}><img src={twitter} width="auto" height="auto" alt="twitter" /></a>
          <div data-href={`https://duefocus.com/blog/${node.md}`}><a rel="noopener noreferrer" target="_blank" href="https://www.facebook.com/sharer/sharer.php?u=https%3A%2F%2Fduefocus.com%2F&amp;src=sdkpreparse"><img src={facebook} width="auto" height="auto" alt="facebook" /></a></div>
        </div>
        <div
          style={{ backgroundImage: 'url(' + node.previewImg.file.url + ')' }}
          className={styles.header}>
          <div>
            <div className={styles.integrations}>{node.category}</div>
            <h1>{node.title}</h1>
            <div>
              <img src="" width="auto" height="auto" alt="" />
              <img src="" width="auto" height="auto" alt="" />
            </div>
          </div>
        </div>
        <div className={styles.block}>
          <div className={styles.left}>
            <img src={node.avatar.file.url} width="auto" height="auto" alt="avatar" />
            <div>
              <span>Published on {moment(node.date).format('DD MMM YYYY')}</span>
              <h5>{node.authorName}</h5>
              <p>{node.authorPosition}</p>
              {/* <h6>8 articles</h6> */}
            </div>
          </div>
          <div className={styles.right}>
            <div>
              <a target='_blank' rel="noopener noreferrer" href={`https://www.linkedin.com/shareArticle?mini=true&url=https://duefocus.com/blog/${node.md}`}><img src={linkedin} width="auto" height="auto" alt="linkedin" /></a>
              <a target='_blank' rel="noopener noreferrer" href={`https://twitter.com/share?url=http://duefocus.com/blog/${node.md}`}><img src={twitter} width="auto" height="auto" alt="twitter" /></a>
              <div data-href={`https://duefocus.com/blog/${node.md}`}><a rel="noopener noreferrer" target="_blank" href="https://www.facebook.com/sharer/sharer.php?u=https%3A%2F%2Fduefocus.com%2F&amp;src=sdkpreparse"><img src={facebook} width="auto" height="auto" alt="facebook" /></a></div>
            </div>
            <button>Subscribe</button>
          </div>
        </div>
        <div className={styles.content}>
          <h2>{node.title}</h2>
          <div dangerouslySetInnerHTML={{ __html: text }}></div>
        </div>
        <div className={styles.relatedPosts}>
          {
            // isHaveRelatedPosts &&
            <div className={styles.head}>
              <h3>Related Posts</h3>
              <span
                onClick={() => window.history.back()}
              >See All</span>
            </div>
          }
          <RelatedPosts
            category={node.category}
            md={node.md}
          //  setRelatedPostsVisibility={setRelatedPostsVisibility}
          />
        </div>
      </div>
      <Footer />
    </>
  )
}
export default React.memo(Template);
export const pageQuery = graphql`
  query($slug: String!)  {
    allContentfulPost(filter: { md: { eq: $slug } }){
        edges {
          node {
            authorName
            authorPosition
            previewText
            date
            category
            title
            md
            description {
                json
                description
            }
            avatar {
              file {
                url
              }
            }
            previewImg {
             file {
                url
              }
            }
          }
        }
      }
    }
  `;

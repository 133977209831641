import React from "react";
import ProgressBar from "react-scroll-progress-bar";
import "react-loader-spinner/dist/loader/css/react-spinner-loader.css"

const ProgressLine = () => {
return(
  <div className="progress-line" >
    <ProgressBar 
      className="progress-line"
      height="4px" 
      bgcolor="#4291EB" 
      duration="1s" 
    />
  </div>
 );
}

export default ProgressLine;